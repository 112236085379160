import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import typefaceFont from 'three/examples/fonts/optimer_bold.typeface.json'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js'
import {
	BokehPass
} from 'three/examples/jsm/postprocessing/BokehPass.js'
var mobile
mobile = false;
var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
import Parallax from 'parallax-js'
import logo from '../assets/images/logo.png'
const logoIcon = document.createElement('img')
logoIcon.src = logo
document.getElementById('logo').appendChild(logoIcon)

var entrance = document.querySelector('.enterBtn');
var parallaxInstance = new Parallax(entrance);
parallaxInstance.relativeInput=true
parallaxInstance.hoverOnly=true

// const pdf1=document.querySelector('#pdf1')
// pdf1.addEventListener('click',()=>{
//     window.location.href='./p-web.html'
// })
/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
const enterBtn = document.querySelector('#enter')
    enterBtn.addEventListener('click',()=>{
        window.location.href='./home.html'
    })
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(85, sizes.width / sizes.height, 0.1, 100)
camera.position.x =3
camera.position.y = -1
camera.position.z = 20
scene.add(camera)
if(isMobile == true){
    camera.fov =40.5
    camera.position.x =10
    camera.position.y = -3
    camera.position.z =100
  
}
// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableRotate =false
controls.enableZoom =false

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/2.png')

/**
 * Fonts
 */
const fontLoader = new THREE.FontLoader()
const texts=[]
fontLoader.load(
    '/fonts/corbyn-r.json',
    (font) =>
    {
        // Material
        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
    
        // Text
        //"PORT"
        const textGeometry = new THREE.TextBufferGeometry(
            'P',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text = new THREE.Mesh(textGeometry, material)
        scene.add(text)
        text.position.set(-3,1,0.2)
        texts.push(text)
              // Text
        const textGeometry2 = new THREE.TextBufferGeometry(
            'O',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text2 = new THREE.Mesh(textGeometry2, material)
        scene.add(text2)
        text2.position.set(-1.8,-0.2,1)
        texts.push(text2)
        const textGeometry3 = new THREE.TextBufferGeometry(
            'R',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text3 = new THREE.Mesh(textGeometry3, material)
        scene.add(text3)
        text3.position.set(-0.8,0.8,-0.6)
        texts.push(text3)
        const textGeometry4 = new THREE.TextBufferGeometry(
            'T',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text4 = new THREE.Mesh(textGeometry4, material)
        scene.add(text4)
        text4.position.set(1,1,0)
        texts.push(text4)
        //"FOLIO"
        const textGeometry5 = new THREE.TextBufferGeometry(
            'F',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text5 = new THREE.Mesh(textGeometry5, material)
        scene.add(text5)
        text5.position.set(-1.4,-1.2,0)
        texts.push(text5)
              // Text
        const textGeometry6 = new THREE.TextBufferGeometry(
            'O',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text6 = new THREE.Mesh(textGeometry6, material)
        scene.add(text6)
        text6.position.set(0,-0.4,0)
        texts.push(text6)
        const textGeometry7 = new THREE.TextBufferGeometry(
            'L',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text7 = new THREE.Mesh(textGeometry7, material)
        scene.add(text7)
        text7.position.set(2,0,-1)
        texts.push(text7)
        const textGeometry8 = new THREE.TextBufferGeometry(
            'I',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text8 = new THREE.Mesh(textGeometry8, material)
        scene.add(text8)
        text8.position.set(0.6,-1.6,1)
        texts.push(text8)

        const textGeometry9 = new THREE.TextBufferGeometry(
            'O',
            {
                font: font,
                size: 0.6,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.02,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 8
            }
        )

        const text9 = new THREE.Mesh(textGeometry9, material)
        scene.add(text9)
        text9.position.set(2.4,-1.6,0.2)
        texts.push(text9)
    }
    
)



/**
 * Post processing
 */
let RenderTargetClass = null

if(renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2)
{
    RenderTargetClass = THREE.WebGLMultisampleRenderTarget
    console.log('Using WebGLMultisampleRenderTarget')
}
else
{
    RenderTargetClass = THREE.WebGLRenderTarget
    console.log('Using WebGLRenderTarget')
}

const renderTarget = new RenderTargetClass(
    800,
    600,
    {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        encoding: THREE.sRGBEncoding
    }
)

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
effectComposer.setSize(sizes.width, sizes.height)

// Render pass
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

const bokehPass = new BokehPass(scene, camera, {
	focus: 6.0,
	aperture: 0.1,
	maxblur: 0.006,
	width: sizes.width,
	height: sizes.height
})
bokehPass.renderToScreen = true
bokehPass.needsSwap = true;
// gui.add(bokehPass, 'enabled').name('bokehpassenabled')
// gui.add(bokehPass.uniforms.maxblur, 'value').min(0).max(1).step(.0001).name('maxblur')
effectComposer.addPass(bokehPass)

// // Glitch pass
const glitchPass = new GlitchPass()
glitchPass.goWild = false
glitchPass.enabled = true
effectComposer.addPass(glitchPass)



window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    // for(const text of texts){
    //     text.rotation.z= 
    // }
    // Update controls
    controls.update()
    camera.position.x=-(Math.cos(Math.pow(1,8)*elapsedTime/4))/2
    camera.position.z =6+(-Math.tan(Math.pow(1,6)*elapsedTime/2))/2
    camera.lookAt(0,0,0)
    // Render
    renderer.render(scene, camera)
    effectComposer.render()
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()